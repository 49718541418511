<template>
  <div class="panel panel-default current-programme-blueprint">
    <div class="panel-body">
      <p>To make changes to a {{blueprintType}} blueprint, click on the blueprint <i class="highlight">name</i> in the table</p>
      <k-table
        :headers="kableHeaders"
        :rows="kableRows"
        :hideable="true"
        :panel="false"
        :max="20"
        @clicked="tableCallback">
      </k-table>
    </div>
    <update-programme-entity
      :item="programmeBlueprintToUpdate"
      :products="products"
      :itemType="blueprintType"
      @close="programmeBlueprintToUpdate = undefined"
      @item-updated="$emit('refresh-blueprints')"
      >
    </update-programme-entity>
  </div>
</template>

<script>
import KTable from '../../../components/k-table.vue';
import UpdateProgrammeEntity from '../../programmes/update-programme-entity.vue';
import PageReadyMixin from '../../../mixins/page-ready-mixin';
import ErrorMixin from '../../../mixins/error-mixins';

export default {
  components: {
    KTable,
    UpdateProgrammeEntity,
  },
  mixins: [ErrorMixin, PageReadyMixin],

  props: {
    products: {
      type: Array,
    },
    programmeBlueprints: {
      type: Array,
    },
    blueprintType: {
      type: String,
    },
  },

  data() {
    return {
      programmeBlueprintToUpdate: undefined,
      confirmDeletionToast: undefined,
    };
  },

  computed: {
    kableHeaders() {
      return {
        edit: {
          name: 'Edit',
          type: 'action',
        },
        name: {
          name: 'Name',
          filterable: true,
          sortable: true,
          type: 'url',
        },
        description: {
          name: 'Description',
          filterable: true,
          sortable: true,
        },
        includeInReport: {
          name: 'Include in report',
        },
        productID: {
          name: 'Product',
        },
        knowledgeBase: {
          name: 'Knowledge Base',
          type: 'action',
          sortable: true,
        },
        kloud: {
          name: 'KLOUD',
          type: 'action',
          sortable: true,
        },
        chatbot: {
          name: 'Chatbot Enabled',
          type: 'action',
          sortable: true,
        },
        delete: {
          name: 'Delete',
          type: 'action',
        },
      };
    },
    kableRows() {
      return this.programmeBlueprints.map((val, index) => ({
        edit: {
          key: index,
          text: '<i class="fas fa-edit"></i>',
        },
        programmeId: val.id,
        name: {
          text: val.name,
          path: { name: 'curriculum_manage_programme_blueprint', params: { programmeBlueprintId: val.id } },
        },
        description: val.description,
        includeInReport: val.include_in_reporting ? 'yes' : 'no',
        productID: this.productName(val.product_id),
        knowledgeBase: {
          key: index,
          enabled: val.enable_kb,
          text: this.enabledIcon(val.enable_kb, 'Knowledge Base'),
        },
        kloud: {
          key: index,
          enabled: val.enable_kloud,
          text: this.enabledIcon(val.enable_kloud, 'KLOUD'),
        },
        chatbot: {
          key: index,
          enabled: val.chatbot_enabled,
          text: this.enabledIcon(val.chatbot_enabled, 'Chatbot'),
        },
        delete: {
          key: index,
          text: '<i class="fas fa-trash-alt"></i>',
        },
      }));
    },
  },
  methods: {
    confirmDeletion(programmeBlueprintToDelete) {
      this.$ktoast.confirmToast(
        `You are about to delete the programme blueprint ${programmeBlueprintToDelete.name}?`,
        'warning',
        () => {
          this.deleteProgrammeBlueprint(programmeBlueprintToDelete);
        },
      );
    },
    deleteProgrammeBlueprint(programmeBlueprintToDelete) {
      this.$logger.info('Deleting programme blueprint', { programmeBlueprintId: programmeBlueprintToDelete.id }, true);
      this.updateInProgress = true;
      return this.$http.delete(`/api/curriculum/blueprints/programmes/${programmeBlueprintToDelete.id}`).then(() => {
        this.$logger.info('Successfully deleted programme blueprint');
        this.$ktoast.success('Blueprint deleted');
        this.$emit('refresh-blueprints');
      }).catch(err => {
        this.$logger.error('Error deleting programme blueprint', undefined, err);
        this.showError(err);
      }).then(() => {
        this.updateInProgress = false;
      });
    },
    tableCallback(key, index, row, col) {
      if (col === 'edit') {
        this.openUpdateProgrammeEntityModal(row.programmeId);
      } else if (col === 'delete') {
        this.confirmDeletion(this.programmeBlueprints[index]);
      } else if (col === 'kloud' || col === 'knowledgeBase' || col === 'chatbot') {
        this.tableFeatureEnableCallback(row, col);
      }
    },
    openUpdateProgrammeEntityModal(programmeId) {
      const prog = this.programmeBlueprints.find(x => x.id === programmeId);
      if (prog && typeof prog === 'object') {
        this.programmeBlueprintToUpdate = JSON.parse(JSON.stringify(prog));
      } else {
        this.programmeBlueprintToUpdate = undefined;
      }
    },
    productName(prodId) {
      const prodIndex = this.products.findIndex(x => x.id === prodId);
      if (prodIndex < 0 || !prodId) {
        return null;
      }
      return this.products[prodIndex].name;
    },
    enabledIcon(enabled, title) {
      if (enabled) {
        return `<div title="${title} enabled for programme. Click to disable." style="text-align: center;"><button class="btn btn-danger" title="Click to disable ${title} for programme">Disable</button></div>`;
      }
      return `<div title="${title} disabled for programme. Click to enable." style="text-align: center;"><button class="btn btn-success" title="Click to enable ${title} for programme">Enable</button></div>`;
    },
    getProgrammeFeatureEnableToastMessage(row, featureEnabled) {
      const featureMap = {
        kloud: 'KLOUD',
        chatbot: 'Chatbot',
      };

      const enabled = row[featureEnabled].enabled;
      const featureEnabledReadable = featureMap[featureEnabled] || 'Knowledge Base';
      if (enabled) {
        return `${featureEnabledReadable} will be disabled for every module and assignment.`;
      }
      return `${featureEnabledReadable} will be enabled for every module and assignment.`;
    },

    enableFeatureInProgramme(programmeId, featureEnabled, enabled) {
      let featureEnabledURL = featureEnabled;
      if (featureEnabled === 'knowledgeBase') {
        featureEnabledURL = 'knowledge-base';
      } else if (featureEnabled === 'chatbot') {
        featureEnabledURL = 'chatbot';
      }
      const enablePref = enabled ? 'dis' : 'en';
      const featureEnabledReadable = featureEnabled === 'kloud' ? 'KLOUD' : 'Knowledge Base';
      this.$logger.info(`${enablePref}abling ${featureEnabledReadable} for programme`, { programmeId });
      return this.$http.put(
        `/api/curriculum/blueprints/programmes/${programmeId}/${enablePref}able-${featureEnabledURL}`,
      ).then(() => {
        this.$logger.info(`${enablePref}abled ${featureEnabledReadable} for programme`, { programmeId });
        this.$ktoast.success(`${featureEnabledReadable} ${enablePref}abled`);
        this.$emit('refresh-blueprints');
      }).catch(err => {
        this.$logger.error(`Error ${enablePref}abling ${featureEnabledReadable} for programme`, { programmeId }, err);
        this.showError(err);
      });
    },

    toastFeatureEnableCallback(row, featureEnabled) {
      const progId = row.programmeId;
      const enabled = row[featureEnabled].enabled;
      this.enableFeatureInProgramme(progId, featureEnabled, enabled);
    },

    tableFeatureEnableCallback(row, featureEnabled) {
      this.$ktoast.confirmToast(
        this.getProgrammeFeatureEnableToastMessage(row, featureEnabled),
        'warning',
        () => {
          this.toastFeatureEnableCallback(row, featureEnabled);
        },
      );
    },
  },
};
</script>
