<template>
  <ul class="k-word-tags-list">
    <li class="k-word-tags-item badge" v-for="tag in tags" :key="tag">
      {{ tag }}
      <k-icon-button v-if="interactive" icon="fas fa-times" aria-label="Remove tag" @click="removeTag(tag)"/>
    </li>
  </ul>
</template>

<style scoped>
.k-word-tags-list:empty {
  display: none;
}

.k-word-tags-list {
  list-style: none;
  margin-top: 10px;
  padding-left: 0;
  display: flex;
}

.k-word-tags-item {
  color: var(--kate-type-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 10px;
  padding: 0 0 0 15px;
}
</style>

<style>
.k-word-tags-item button {
  color: var(--kate-type-white);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 5px 8px;
}

.k-word-tags-item button:hover {
  color: var(--kate-type-white);
  background-color: var(--kate-danger);
}
</style>

<script>
import KIconButton from '../../components/buttons/k-icon-button.vue';

export default {
  components: {
    KIconButton,
  },

  props: {
    modelValue: {
      type: Array,
    },
    interactive: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    this.tags = this.modelValue;
  },

  data() {
    return {
      tags: [],
    };
  },

  watch: {
    modelValue() {
      this.tags = this.modelValue;
    },
    tags() {
      this.$emit('update:modelValue', this.tags);
    },
  },

  methods: {
    removeTag(tag) {
      this.tags = this.tags.filter(x => x !== tag);
    },
  },
};
</script>
