<template>
  <div class="k-editable-table-container">
    <table class="table table-hoverable k-edit-table">
      <thead>
        <tr>
          <th v-for="(entry, index) in headers" :key="index" class="k-edit-table-th">
            <div class="k-edit-table-header">
              <span class="header-left">
                <span class="header-title" v-html="$kpurify.sanitise(entry.title)"></span>
                <div class="header-controls">
                  <div class="table-search" v-if="entry.filterable">
                    <input :id="`editable-table-search-${$.uid}-${entry.key || index}`" type="text" class="form-control search-input" v-model="searchFilters[entry.key]">
                    <button type="submit" title="Submit" aria-label="Submit">
                      <label :for="`editable-table-search-${$.uid}-${entry.key || index}`"><i class="fa fa-search"></i></label>
                    </button>
                  </div>
                  <div class="header-control-icon">
                    <k-tooltip text="Sort by column">
                      <button class="btn btn-filters header-control-button" v-if="entry.sortable !== false" @click="setSort(entry)" title="Sort" aria-label="Sort">
                        <i :class="sortClass"></i>
                      </button>
                    </k-tooltip>
                    <span>
                    </span>
                  </div>
                </div>
              </span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <kr
          v-for="(row, index) in paginatedRows"
          :key="index"
          :row="row"
          :class-func="rowClassFunc"
          :headers="headers"
          :timezone="timezone"
          @update="updateCell(row._index, $event)"
          @commitEdits="commitEdits"
        ></kr>
      </tbody>
    </table>
    <pagination class="k-editable-table-pagination" v-model="currentPage" :count="numOfPages" v-if="numOfPages" :total-entries="modelValue.length" :displayed-entries="paginatedRows.length"></pagination>
  </div>
</template>

<style>
table.k-edit-table,
.k-edit-table > tbody > tr > th,
.k-edit-table > tbody > tr > td {
  border-collapse: collapse;
  border: 1px solid var(--kate-background);
}

.k-edit-table-th {
  background-color: var(--kate-background-body);
  color: var(--kate-type-light);
}

.header-controls {
  line-height: 1;
  display: flex;
}

.k-edit-table-header {
  display: block;
  min-width: max-content;
  line-height: 2;
  white-space: nowrap;
}

.k-edit-table .btn-filters {
  background-color: transparent;
  color: var(--kate-type-link);
  border: 0;
}

.k-edit-table .btn-filters:hover {
  background-color: var(--kate-primary-dark);
  color: var(--kate-type-light);
}

.k-edit-table .btn-filters:active {
  background-color: var(--kate-primary-dark);
  color: var(--kate-type-light);
}

.k-edit-table .btn-filters:focus {
  background-color: var(--kate-primary-dark);
  color: var(--kate-type-light);
}

.k-edit-table .btn-filters i.fas.fa-trash {
  color: var(--kate-danger-alt);
}

.k-edit-table-header .header-left {
  display: flex;
  align-items: center;
}

.k-editable-table-pagination.panel-pagination.row {
  margin: 15px -15px;
}

.k-editable-table-row:nth-child(even) {
  background-color: var(--kate-background-body-alpha);
}

.k-editable-table-row:nth-child(odd) {
  background-color: var(--kate-background-alt-alpha);
}

</style>

<script>
/* eslint no-underscore-dangle: ["error", { "allow": ["_index"] }] */
import KRow from './k-editable-table-row.vue';
import KTooltip from './k-tooltip.vue';
import Pagination from './k-pagination.vue';
import stringMatch from '../modules/string-match';

export default {
  components: {
    kr: KRow,
    KTooltip,
    Pagination,
  },

  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    rowClassFunc: {
      type: Function,
    },
    max: Number,
    timezone: String,
  },

  watch: {
    numOfPages() {
      this.currentPage = 0;
    },
  },

  data: () => ({
    popover: {
      visibility: 'focus',
    },
    searchFilters: {},
    currentSortHeader: null,
    sortAscending: true,
    currentPage: 0,
  }),

  computed: {
    refinedRows() {
      return this.modelValue
        .map((x, index) => ({ ...x, _index: index }))
        .filter(this.isRowVisible)
        .sort(this.sortingFunction);
    },
    sortMultiplier() {
      return this.sortAscending ? 1 : -1;
    },
    sortClass() {
      return this.sortAscending ? 'fas fa-sort-amount-up' : 'fas fa-sort-amount-down';
    },

    /* Pagination */
    numOfPages() {
      if (!this.max || this.refinedRows.length <= this.max) {
        return undefined;
      }
      return Math.ceil(this.refinedRows.length / this.max);
    },

    paginatedRows() {
      if (!this.numOfPages) {
        return this.refinedRows;
      }
      const output = [];
      const startingIndex = this.max * this.currentPage;
      const endingIndex = Math.min(this.max + startingIndex, this.refinedRows.length);
      for (let i = startingIndex; i < endingIndex; i++) {
        output.push(this.refinedRows[i]);
      }
      return output;
    },
  },

  methods: {
    isRowVisible(row) {
      let header;
      let key;
      let valToAnalyse;
      for (let i = 0; i < this.headers.length; i++) {
        header = this.headers[i];
        key = header.key;
        if (header.filterable && key in this.searchFilters) {
          if ('text' in header) {
            valToAnalyse = header.text(row[key]);
          } else {
            valToAnalyse = row[key];
          }
          const filterText = this.searchFilters[key];
          if (Array.isArray(valToAnalyse)) {
            return Boolean(valToAnalyse.find(x => stringMatch(x, filterText, true))
                                                  || (valToAnalyse.length === 0 && filterText === ''));
          }
          if (!stringMatch(valToAnalyse, filterText)) {
            return false;
          }
        }
      }
      return true;
    },
    update(newRows) {
      this.$emit('update:modelValue', newRows);
    },
    updateCell(index, nuval) {
      const newRows = this.modelValue.slice();
      newRows[index] = nuval;
      this.update(newRows);
    },
    commitEdits(payload) {
      this.$emit('commitEdits', payload);
    },
    setSort(header) {
      if (this.currentSortHeader && this.currentSortHeader.key === header.key) {
        this.sortAscending = !this.sortAscending;
      } else {
        this.currentSortHeader = header;
      }
    },
    sortingFunction(a, b) {
      if (!this.currentSortHeader) {
        return 0;
      }
      const aObj = a[this.currentSortHeader.key];
      const bObj = b[this.currentSortHeader.key];
      let A;
      let B;
      if ('sortBy' in this.currentSortHeader) {
        A = this.currentSortHeader.sortBy(aObj);
        B = this.currentSortHeader.sortBy(bObj);
      } else {
        A = aObj;
        B = bObj;
      }
      if (A < B) {
        return -1 * this.sortMultiplier;
      }
      if (A > B) {
        return 1 * this.sortMultiplier;
      }
      return 0;
    },
  },
};
</script>
