<template>
  <k-modal class="k-change-programme-modal" @close="close" :show="show">
    <template #header>
      <h3>KATE Programmes</h3>
    </template>
    <template #body>
      <div class="k-change-programme-body">
        <div class="k-change-programme-sub-header">
          <div class="k-change-programme-search-bar">
            <span class="fa fa-search search-icon"></span>
            <label for="search-programme-input">
              <input id="search-programme-input" class="form-control" type="text" placeholder="Search" v-model="filterText">
            </label>
          </div>
          <div class="k-change-programme-filters">
            <div class="date-filter">
              <k-date-picker
                v-model="filterStartDate"
                inputPlaceholder="From">
              </k-date-picker>
              <div class="spacer"></div>
              <k-date-picker
                v-model="filterEndDate"
                :minDate="filterStartDate"
                inputPlaceholder="To">
              </k-date-picker>
            </div>
          </div>
        </div>
        <div class="k-change-programme-list">
          <div class="list-container">
            <table class="prog-table">
              <thead>
                <tr class="prog-headers">
                  <th>Programme</th>
                  <th>Date</th>
                  <th>KLOUD</th>
                </tr>
              </thead>
              <tbody>
                <tr class="prog-row" v-for="g in filteredProgrammes.programmes" :key="g.id" @click="chooseProgramme(g.id)">
                  <td class="prog-name">{{g.name}}</td>
                  <td class="prog-date">{{parseTimestamp(g.date_created, undefined, "do MMM yyyy")}}</td>
                  <td class="prog-kloud">
                    <span v-if="g.kloud_enabled" class="kloud-enabled">Enabled</span>
                    <span v-else>Disabled</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </k-modal>
</template>

<style>
.k-change-programme-modal .modal-body {
  margin-bottom: 0;
}

.k-change-programme-modal .modal-container {
  min-width: 45%;
}

.k-date-picker label:empty {
  display: none;
}
</style>

<style scoped>
.k-change-programme-sub-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Search */
.search-icon {
  position: absolute;
  display: block;
  width: 38px;
  left: 0;
  height: 38px;
  line-height: 38px;
  text-align: center;
  pointer-events: none;
  color: var(--kate-type-primary);
}

.k-change-programme-search-bar {
  text-align: left;
  position: relative;
}

.k-change-programme-search-bar label {
  margin-bottom: 0;
}

.k-change-programme-search-bar input {
  height: 38px;
  font-size: 1em;
  width: 100%;
  display: block;
  padding: 5px 5px 5px 38px;
  border: var(--input-border);
  color: var(--kate-type-light);
  background-color: var(--input-background);
  outline: 0;
}

.k-change-programme-filters {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.k-change-programme-filters .date-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10px;
}

.k-change-programme-filters .date-filter .spacer {
  width: 10px;
}

/* Programmes */
.list-container {
  height: 300px;
  overflow-y: auto;
  border-bottom: 2px solid var(--kate-mono-0);
}

.list-container table.prog-table {
  width: 100%;
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
}

.prog-headers th {
  text-transform: uppercase;
  background: var(--kate-background-body);
  position: sticky;
  top: 0;
  border-bottom: 3px solid var(--kate-mono-0);
  padding: 10px 5px;
  min-width: 3em;
}

.prog-row {
  cursor: pointer;
  text-align: left;
}

.prog-row td {
  color: var(--kate-type-light);
  border-bottom: 1px solid var(--kate-background-body-alpha);
  padding: 6px 5px;
}

.prog-row td:first-child {
  font-style: italic;
  color: var(--kate-type-link);
}

.prog-row:hover {
  background-color: var(--kate-background-alt-alpha);
}

.prog-row td span.kloud-enabled {
  color: var(--kate-success);
  font-weight: bold;
}

.prog-row .prog-type {
  text-align: center;
}

</style>

<script>
import KModal from '../../components/k-modal.vue';
import KDatePicker from '../../components/k-date-picker.vue';
import TimeMixin from '../../mixins/time-mixins';
import stringMatch from '../../modules/string-match';

export default {
  mixins: [TimeMixin],
  emits: ['update:modelValue'],
  components: {
    'k-modal': KModal,
    'k-date-picker': KDatePicker,
  },
  props: {
    programmes: {
      type: Array,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    modelValue: Number,
  },
  data() {
    return {
      filterText: '',
      filterStartDate: undefined,
      filterEndDate: undefined,
    };
  },
  computed: {
    filteredProgrammes() {
      return {
        programmes: this.programmes.filter(this.filterProgrammes).sort(this.dateSortDescending),
        // Trigger programme filtering whenever filters change
        filters: {
          text: this.filterText,
          start: this.filterStartDate,
          end: this.filterEndDate,
        },
      };
    },
  },
  methods: {
    clearFilters() {
      this.filterStartDate = undefined;
      this.filterEndDate = undefined;
      this.filterText = '';
    },
    textFilter(prog) {
      return stringMatch(prog.name, this.filterText)
        // Strict match on key and tags
        || stringMatch(prog.template_key, this.filterText, true)
        || (prog.tags && prog.tags.filter(tag => stringMatch(tag, this.filterText, true)).length > 0);
    },
    dateFilter(prog) {
      if (!this.filterStartDate && !this.filterEndDate) {
        return true;
      }
      if (!prog.date_created) {
        return false;
      }
      if ((!this.filterStartDate || this.getDate(prog.date_created) >= this.filterStartDate)
          && (!this.filterEndDate || this.getDate(prog.date_created) <= this.filterEndDate)) {
        return true;
      }
      return false;
    },
    filterProgrammes(prog) {
      return this.textFilter(prog) && this.dateFilter(prog);
    },
    dateSortDescending(a, b) {
      if (a.date_created && b.date_created) {
        if (a.date_created >= b.date_created) {
          return -1;
        }
      } else if (b.date_created) {
        return -1;
      }
      return 1;
    },
    chooseProgramme(id) {
      this.$emit('update:modelValue', id);
      this.close();
    },
    close() {
      this.clearFilters();
      this.$emit('close');
    },
  },
};
</script>
