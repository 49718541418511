<template>
  <div class="quiz-question-answer col-md-12 row">
    <fieldset class="detail-form form-entry" :class="colClass">
      <label :for="`text-${$.uid}`">Answer</label>
      <textarea :id="`text-${$.uid}`" rows="1" class="form-control" placeholder="Answer text here..." v-model="answer"></textarea>
    </fieldset>
    <div v-if="setCorrect" class="detail-form form-toggle col-md-2 col-sm-2 col-xs-3">
      <k-toggle :round="true" v-model="is_correct" size="small" label="mark as correct" :class-id="`ans-${$.uid}`"></k-toggle>
    </div>
    <k-tooltip text="Remove answer" text-align="center" class="detail-form remove-answer-container col-md-1 col-sm-1 col-xs-1">
      <button class="fas fa-times remove-answer btn-icon" title="Remove answer" @click="removeAnswer"></button>
    </k-tooltip>
  </div>
</template>

<style scoped>
.quiz-question-answer {
  padding: 0;
  margin: 5px 0;
  display: flex;
  align-items: flex-end;
}

.detail-form {
  padding: 0;
  margin-bottom: 10px;
}

.form-toggle {
  display: flex;
  margin: 0;
}

.remove-answer {
  cursor: pointer;
  font-size: 1.2rem;
  padding: 10px;
}

.remove-answer:hover {
  color: var(--kate-danger-light);
}

textarea {
  resize: vertical;
  min-height: 30px;
}

.row {
  padding-left: 0;
  padding-right: 0;
}

.remove-answer-container {
  text-align: center;
}
</style>

<style>
.quiz-question-answer .form-toggle .k-toggle {
  padding-left: 15px;
}
</style>

<script>
import KTooltip from '../../../../../components/k-tooltip.vue';
import KToggle from '../../../../../components/forms/k-toggle.vue';

export default {
  components: {
    'k-toggle': KToggle,
    'k-tooltip': KTooltip,
  },
  props: {
    modelValue: {
      type: [String, Object],
    },
    setCorrect: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      answer: this.setCorrect ? this.modelValue.answer : this.modelValue,
      is_correct: this.setCorrect ? this.modelValue.is_correct : null,
    };
  },

  watch: {
    modelValue() {
      this.answer = this.setCorrect ? this.modelValue.answer : this.modelValue;
      this.is_correct = this.setCorrect ? this.modelValue.is_correct : null;
    },
    quizQuestionAnswer() {
      this.$emit('update:modelValue', this.setCorrect ? Object.assign(this.modelValue, this.quizQuestionAnswer) : this.quizQuestionAnswer);
    },
  },

  computed: {
    colClass() {
      const w = this.setCorrect ? 9 : 11;
      return `col-md-${w} col-sm-${w} col-xs-${w - 1}`;
    },
    quizQuestionAnswer() {
      return this.setCorrect ? {
        answer: this.answer,
        is_correct: this.is_correct,
      } : this.answer;
    },
  },

  methods: {
    removeAnswer() {
      this.$emit('remove');
    },
  },
};
</script>
