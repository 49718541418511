<template>
  <div class="cell-row" :class="header.type">
    <span class="cell-display">
      <template v-if="editing">
        <template v-if="header.type === 'string'">
          <form @submit.prevent="finishCell">
            <k-text-search ref="stringinputedit" v-model="editSpace"></k-text-search>
          </form>
        </template>
        <template v-if="header.type === 'input-text'">
          <form @submit.prevent="finishCell">
            <k-text-input ref="textinputedit" v-model="editSpace"></k-text-input>
          </form>
        </template>
        <template v-if="header.type === 'multi-input-selection'">
          <form @submit.prevent="() => {}" class="cell-controls">
            <div v-if="header.options && header.options.length > 0">
              <word-tags v-model="editSpace" textInputPlaceholder="Type tag here..." :options="header.options"></word-tags>
            </div>
            <div v-else class="no-option-warning">
              <p>
                <i class="fas fa-exclamation-triangle"></i>
                There are no options to select
              </p>
            </div>
          </form>
        </template>
        <template v-if="header.type === 'multi-input'">
          <form @submit.prevent="() => {}" class="cell-controls">
            <word-tags v-model="editSpace" textInputPlaceholder="Type tag here..."></word-tags>
          </form>
        </template>
        <template v-else-if="header.type === 'multi-link-input'">
          <div v-for="(link, index) in entry" :key="index">
            <form v-if="editingArrayElement === index" @submit.prevent="finishCell" class="cell-controls">
              <k-text-input ref="textinputedit" v-model="editSpace[index]"></k-text-input>
              <button class="btn btn-success k-table-cell-button save" @click="finishCell" title="Save changes" aria-label="Save changes">
                <i class="fas fa-check"></i>
              </button>
            </form>
            <div v-else>
              <a :href="link">{{ link }}</a>
            </div>
          </div>
        </template>
        <template v-else-if="header.type === 'date' || header.type === 'datetime'">
          <k-date class="cell-date" v-model="editSpace" :type="header.type" :minuteIncrement="header.increment" :timezone="timezone" :minDate="header.minDate"></k-date>
        </template>
        <template v-else-if="header.type === 'time'">
          <k-time v-model="editSpace"></k-time>
        </template>
      </template>
      <template v-else-if="header.type === 'dropdown'">
        <span v-if="header.rowOptions">
          <k-dropdown v-if="header.rowOptions[row._index].length > 0" v-model="editSpace" :options="header.rowOptions[row._index]" :show-search="false" placeholder="select" :key="entry">
          </k-dropdown>
          <span v-else>No selectable options</span>
        </span>
        <k-dropdown v-else v-model="editSpace" :options="header.options" :show-search="false" placeholder="select" :key="entry"></k-dropdown>
      </template>
      <template v-else-if="showDatePlaceHolder">
        <i class="select-date">Select a date</i>
      </template>
      <template v-else-if="header.type === 'action'">
        <div v-html="header.html" class="action-container" @click="header.callback(row, row._index, header)">
        </div>
      </template>
      <template v-else-if="header.type === 'html'">
        <div v-html="header.html(entry)">
        </div>
      </template>
      <template v-else-if="header.type === 'multi-input-selection' || header.type === 'multi-input'">
        <span>
          <ul class="k-word-tags-list">
            <li class="k-word-tags-item badge" v-for="group in entry" :key="group">
              {{group}}
            </li>
          </ul>
        </span>
      </template>
      <template v-else-if="header.type === 'multi-link-input'" >
        <div v-for="(link, index) in entry" class="multi-input-cell-control">
          <a :href="link" class="me-auto">{{ link }}</a>
          <div class="cell-controls">
            <button class="btn btn-filters edit-link" @click="editCellElement(index)" title="Edit" aria-label="Edit">
              <i class="fas fa-edit"></i>
            </button>
            <button class="btn btn-filters remove-link"
                    @click="updateCell(editSpace.filter((el, i) => i !== index), true)"
                    title="Delete"
                    aria-label="Delete">
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
        <div class="multi-input-cell-control">
          <div class="cell-controls">
            <button class="btn btn-filters add-link" @click="addArrayEl" title="Add" aria-label="Add">
              <i class="fas fa-plus"></i>
            </button>
          </div>
        </div>
      </template>
      <template v-else-if="header.type === 'boolean'">
        <label class="k-checkbox" :for="'k-checkbox-input-' + rowIndex">
          <input :id="'k-checkbox-input-' + rowIndex"
            type="checkbox"
            title="checkbox"
            aria-label="checkbox"
            @click="updateCell(!entry)"
            v-model="editSpace">
        </label>
      </template>
      <template v-else>
        {{display(entry, header)}}
      </template>
    </span>
    <span class="cell-controls" v-if="showEdit(header)">
      <template v-if="editing && header.type !== 'multi-link-input'">
        <button class="btn btn-success k-table-cell-button" @click="finishCell" title="Save changes" aria-label="Save changes">
          <i class="fas fa-check"></i>
        </button>
      </template>
      <template v-else>
        <button v-if="header.type !== 'boolean' && header.type !== 'multi-link-input'" class="btn btn-filters" title="Edit" aria-label="Edit" @click="editCell">
          <i class="fas fa-edit"></i>
        </button>
        <button v-if="!header.required && entry && header.type !== 'multi-link-input'" class="btn btn-filters" title="Delete" aria-label="Delete" @click="updateCell(undefined, true)">
          <i class="fas fa-trash-alt"></i>
        </button>
      </template>
    </span>
  </div>
</template>
<style>
.cell-display,
.cell-display li {
  width: 100%;
  color: var(--kate-type-light) !important;
}

.cell-controls,
.multi-input-cell-control {
  display: inline-flex;
}

.multi-input-cell-control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 5px;
}

.k-editable-table-row .cell-row {
  display: flex;
  align-items: center;
}

.k-editable-table-row .cell-row.boolean,
.k-editable-table-row .cell-row.action {
  text-align: center;
}

.select-date {
  color: var(--kate-type-primary);
}

.multi-input-selection {
  align-items: baseline !important;
}

.multi-input-selection .btn-success {
  margin-left: 5px;
}

.multi-input .k-word-tags-item,
.multi-input-selection .k-word-tags-item {
  width: auto !important;
}

.k-editable-table-row .k-text-input {
  margin: 0;
}

.cell-row.input-text .cell-display .field-label:empty {
  display: none;
}

</style>
<style scoped>
.k-word-tags-list {
  list-style: none;
  margin-top: 10px;
  padding-left: 0;
}

.k-table-cell-button.btn-success {
  padding: 8px 12px;
}

.me-auto {
  white-space: nowrap;
}
</style>

<script>
import KDate from './k-date-picker.vue';
import KTime from './k-time-picker.vue';
import KDropdown from './k-dropdown.vue';
import TimeMixin from '../mixins/time-mixins';
import KTextSearch from './k-text-search.vue';
import KTextInput from './k-text-input.vue';
import WordTags from '../dashboard/components/word-tags.vue';

export default {
  mixins: [TimeMixin],
  components: {
    WordTags,
    'k-time': KTime,
    'k-date': KDate,
    'k-dropdown': KDropdown,
    'k-text-search': KTextSearch,
    'k-text-input': KTextInput,
  },
  props: {
    header: {
      type: Object,
    },
    entry: {
      type: [Object, String, Boolean, Date, Number, Array],
    },
    row: {
      type: Object,
    },
    timezone: String,
  },
  data: () => ({
    editing: false,
    editSpace: undefined,
    addNew: undefined,
    multiEntryEditingIndex: undefined,
  }),
  beforeMount() {
    this.editSpace = this.entry;
  },
  watch: {
    editSpace: {
      handler() {
        this.updateCell(this.editSpace);
      },
      deep: true,
    },
    entry: {
      handler() {
        this.editSpace = this.entry;
      },
      deep: true,
    },
  },

  computed: {
    showDatePlaceHolder() {
      if (this.header.type === 'date' || this.header.type === 'datetime') {
        if (this.entry === null || this.entry === undefined) {
          return true;
        }
      }
      return false;
    },
    editingArrayElement() {
      return this.multiEntryEditingIndex;
    },
  },

  methods: {
    display(obj, header) {
      if ('text' in header) {
        return header.text(obj);
      }
      if (header.type === 'date') {
        return this.parseTimestamp(obj, undefined, 'do MMM yyyy');
      }
      if (header.type === 'datetime') {
        return this.parseTimestamp(obj, undefined, 'do MMM yyyy HH:mm', this.timezone);
      }
      if (header.type === 'time') {
        return obj;
      }
      return obj;
    },
    addArrayEl() {
      this.editCell();
      // Make sure it is an array
      if (!this.editSpace) {
        this.editSpace = [];
      }
      this.editSpace.push('');
      this.multiEntryEditingIndex = this.editSpace.length - 1;
    },
    editCellElement(index) {
      this.multiEntryEditingIndex = index;
      this.editCell();
    },
    editCell() {
      this.editSpace = this.entry;
      this.editing = true;
      if (this.header.type === 'string') {
        this.$nextTick(() => {
          this.$refs.stringinputedit.focus();
        });
      }
    },
    showEdit(header) {
      if (!header.editable) {
        return false;
      }
      if (typeof header.editable === 'function') {
        return header.editable(this.row);
      }
      return header.type !== 'dropdown' && header.type !== 'boolean';
    },
    update(newRow) {
      this.$emit('update', newRow);
    },
    updateCell(nuval, commit) {
      this.update(nuval);
      if (commit) {
        this.$nextTick(() => {
          this.commitEdits();
        });
      }
    },
    finishCell() {
      this.editing = false;
      this.commitEdits();
    },
    commitEdits() {
      this.$emit('commitEdits', this.row);
    },
  },
};
</script>
